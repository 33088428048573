import React, { useCallback, useEffect, useState } from "react";
import { baseUrl } from "../lib/helpers/constants";
import {
  PayoutResponse,
  PayoutPollResponse,
  BankData,
} from "../lib/helpers/interfaces";
import { useNavigate } from "react-router-dom";
import { CheckCircle, XCircle } from "@phosphor-icons/react";
import { user } from "../lib/helpers/auth";
import LoadingModal from "../components/LoadingModal";
import * as Yup from "yup";
import { useFormik } from "formik";

type FormikValues = {
  full_name: string;
  amount: number;
  phone_number: string;
  account_number: string;
  bank_id: string;
  payment_mode: string;
};

const validationSchema = Yup.object().shape({
  payment_mode: Yup.string(),
  full_name: Yup.string()
    .required("Please provide your name")
    .max(50, "Please use 50 characters"),
  amount: Yup.number()
    .required("Please provide a valid amount")
    .min(10, "Minuimum withdrawal limit is KES 10"),
  phone_number: Yup.string().when("payment_mode", {
    is: (val: string) => val.includes("ESA"),
    then: () =>
      Yup.string()
        .required("Phone number is required")
        .matches(/^254/, "Use the format 254XXXXXXXXX")
        .max(12, "Length should be 12 characters")
        .matches(/^[0-9]+$/, "Must be only digits"),
    otherwise: () => Yup.string(),
  }),
  account_number: Yup.string().when("payment_mode", {
    is: (val: string) => val.includes("BANK"),
    then: () =>
      Yup.string()
        .required("Account number is required")
        .matches(/^[0-9]+$/, "Must be only digits"),
    otherwise: () => Yup.string(),
  }),
  bank_id: Yup.string().when("payment_mode", {
    is: (val: string) => val.includes("BANK"),
    then: () => Yup.string().required("Please select a bank"),
    otherwise: () => Yup.string(),
  }),
});

const PayOut = () => {
  const [loading, setLoading] = useState(false);
  const [payoutID, setPayoutID] = useState("");
  const [success, setSuccess] = useState(false);
  const [failure, setFailure] = useState(false);
  const [method, setMethod] = useState("MPESA");
  const [banks, setBanks] = useState<BankData[]>();
  const navigation = useNavigate();

  const { values, errors, handleChange, handleSubmit, isValid, setFieldValue } =
    useFormik({
      initialValues: {
        payment_mode: "MPESA",
        full_name: "",
        amount: 0,
        phone_number: "",
        account_number: "",
        bank_id: "",
      },
      onSubmit: (vals) => handleAuthorise(vals),
      validationSchema,
      isInitialValid: false,
    });

  const fetchBanks = useCallback(async () => {
    try {
      const res = await fetch(baseUrl + "/ipsl-banks", {
        method: "GET",
        headers: {
          Authorization: user.value?.token || "",
          merchantMembership: user.value?.merchant_membership?.id || "",
        },
      });
      const data: BankData[] = await res.json();
      if (data) {
        setBanks(data);
        console.log(data);
      }
    } catch (err) {
      console.error(err);
    }
  }, []);

  const pollPayOut = useCallback(async () => {
    if (loading && !!payoutID) {
      try {
        const res = await fetch(baseUrl + "/payouts/" + payoutID, {
          method: "GET",
          headers: {
            Authorization: user.value?.token || "",
          },
        });
        const data: PayoutPollResponse = await res.json();
        if (data.state === "SUCCESS") {
          setLoading(false);
          setSuccess(true);
        } else if (data.state === "PENDING" || data.state === undefined) {
          return;
        } else {
          setLoading(false);
          setFailure(true);
        }
      } catch (e) {
        console.error(e);
      }
    }
  }, [loading, payoutID]);

  const handleAuthorise = useCallback(
    async ({
      amount,
      full_name,
      phone_number,
      payment_mode,
      account_number,
      bank_id,
    }: FormikValues) => {
      if (!user.value?.user) return;
      setLoading(true);
      try {
        const res = await fetch(baseUrl + "/payouts", {
          headers: {
            Authorization: "Bearer " + user.value?.token || "",
            merchantMembership: user.value?.merchant_membership?.id || "",
          },
          method: "POST",
          body: JSON.stringify({
            amount,
            payment_mode,
            full_name,
            email: user.value.user.email,
            phone_number,
            bank_id,
            account_number,
          }),
        });
        const data: PayoutResponse = await res.json();

        if (data) {
          setPayoutID(data.id);
        }
      } catch (err) {
        console.error(err);
      }
    },
    []
  );

  useEffect(() => {
    if (!loading || !payoutID) return;
    const intervalID = setInterval(pollPayOut, 1500);
    return () => clearInterval(intervalID);
  }, [loading, payoutID, pollPayOut]);

  useEffect(() => {
    if (!!banks?.length) return;
    fetchBanks();
  }, [banks?.length, fetchBanks]);
  console.log(errors);
  return success || failure ? (
    <>
      <div className="text-center">
        {success ? (
          <CheckCircle
            size={128}
            weight="regular"
            style={{ margin: "0 auto" }}
          />
        ) : (
          <XCircle size={128} weight="regular" style={{ margin: "0 auto" }} />
        )}
        <p className="mt-4">
          Your payment has been {failure && "un"}successful
        </p>
      </div>
      <button
        className="w-full mt-4 block rounded-md border-gray-300 bg-blue-600 p-2 text-white"
        onClick={() => navigation("/")}
      >
        Go Home
      </button>
    </>
  ) : (
    <>
      {loading && <LoadingModal />}
      <h1 className="text-xl font-semibold">Authorise Payout</h1>
      <h1 className="text-lg font-medium my-3">
        Choose settlement method below
      </h1>
      <ul className="flex flex-wrap justify-left mt-4">
        <li
          onClick={(e) => {
            setMethod("MPESA");
            setFieldValue("payment_mode", "MPESA");
          }}
          className={
            method === "MPESA"
              ? "border-b-2 border-gray-800 mr-4 cursor-pointer"
              : "mr-4 cursor-pointer"
          }
        >
          M-Pesa
        </li>
        <li
          onClick={(e) => {
            setMethod("BANK");
            setFieldValue("payment_mode", "BANK");
          }}
          className={
            method === "BANK"
              ? "border-b-2 border-gray-800 mr-4 cursor-pointer"
              : "mr-4 cursor-pointer"
          }
        >
          Bank
        </li>
      </ul>
      <form onSubmit={handleSubmit}>
        {method === "MPESA" ? (
          <>
            <div className="mt-2">
              <span className="text-gray-700 mt-1 text-sm">Full name</span>
              <input
                type="text"
                name="full_name"
                className="mt-1 block w-full rounded-md border-gray-300"
                placeholder=""
                value={values.full_name}
                onChange={handleChange}
              />
              {errors.full_name && (
                <span className="text-red-500 text-xs">{errors.full_name}</span>
              )}
            </div>
            <div className="mt-2">
              <span className="text-gray-700 mt-1 text-sm">Phone number</span>
              <input
                type="text"
                name="phone_number"
                className="mt-1 block w-full rounded-md border-gray-300"
                placeholder=""
                value={values.phone_number}
                onChange={handleChange}
              />
              {errors.phone_number && (
                <span className="text-red-500 text-xs">
                  {errors.phone_number}
                </span>
              )}
            </div>
            <div className="mt-2">
              <span className="text-gray-700 mt-1 text-sm">Amount</span>
              <input
                type="number"
                name="amount"
                className="mt-1 block w-full rounded-md border-gray-300"
                placeholder=""
                value={values.amount}
                onChange={handleChange}
              />
              {errors.amount && (
                <span className="text-red-500 text-xs">{errors.amount}</span>
              )}
            </div>
          </>
        ) : (
          <>
            <div className="mt-2">
              <span className="text-gray-700 mt-1 text-sm">Full name</span>
              <input
                type="text"
                name="full_name"
                className="mt-1 block w-full rounded-md border-gray-300"
                placeholder=""
                value={values.full_name}
                onChange={handleChange}
              />
              {errors.full_name && (
                <span className="text-red-500 text-xs">{errors.full_name}</span>
              )}
            </div>
            <div className="mt-2">
              <span className="text-gray-700 mt-1 text-sm">Bank</span>
              <select
                id="banks"
                name="bank_id"
                onChange={handleChange}
                className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              >
                <option value={""} selected>
                  Choose a bank
                </option>
                {!!banks?.length &&
                  banks.map((bank) => (
                    <option key={bank.id} value={bank.id}>
                      {bank.name}
                    </option>
                  ))}
              </select>
            </div>
            {errors.bank_id && (
              <span className="text-red-500 text-xs">{errors.bank_id}</span>
            )}
            <div className="mt-2">
              <span className="text-gray-700 mt-1 text-sm">Account Number</span>
              <input
                type="text"
                name="account_number"
                className="mt-1 block w-full rounded-md border-gray-300"
                placeholder=""
                value={values.account_number}
                onChange={handleChange}
              />
              {errors.account_number && (
                <span className="text-red-500 text-xs">
                  {errors.account_number}
                </span>
              )}
            </div>
            <div className="mt-2">
              <span className="text-gray-700 mt-1 text-sm">Amount</span>
              <input
                type="number"
                name="amount"
                className="mt-1 block w-full rounded-md border-gray-300"
                placeholder=""
                value={values.amount}
                onChange={handleChange}
              />
              {errors.amount && (
                <span className="text-red-500 text-xs">{errors.amount}</span>
              )}
            </div>
          </>
        )}
        <button
          className={`w-full mt-4 block rounded-md border-gray-300  ${
            isValid ? "bg-[#212121] text-[#FCFCFC]" : "bg-[#D0CECD]"
          } p-2 text-[#212121]`}
          type="submit"
        >
          Authorise
        </button>
      </form>
    </>
  );
};

export default PayOut;
