import { baseUrl } from "./constants";
import { LoginResponse, MerchantType } from "./interfaces";
import { Signal, computed, signal } from "@preact/signals-react";

export const user: Signal<LoginResponse | undefined> = signal(undefined);

const validateCreds = async (token: string, merchantMembership: string) => {
    try {
        const res = await fetch(baseUrl + '/merchants', {
            method: 'GET',
            headers: {
                merchantMembership,
                Authorization: "Bearer " + token
            }
        })
        if (!res.ok) { localStorage.removeItem('site'); user.value = undefined }
        const data: MerchantType = await res.json()
        if (!data.id) { localStorage.removeItem('site'); user.value = undefined }
    } catch (err) { console.error(err) }
}

export const isLoggedIn = computed(() => {
    const localString = localStorage.getItem('site')
    user.value = JSON.parse(localString || '')
    if (!user.value && !!localString) {
        const localData: LoginResponse = JSON.parse(localString)
        validateCreds(localData.token, localData.merchant_membership?.id || '')
        return !!user.value
    }
    return !!user.value;
});

export const logOut = () => {
    user.value = undefined;
};
export const login = (data: LoginResponse) => {
    localStorage.setItem('site', JSON.stringify(data))
    user.value = data;
};