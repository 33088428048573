import React, { useCallback, useState } from "react";
import { baseUrl } from "../lib/helpers/constants";
import { LinkResponse } from "../lib/helpers/interfaces";
import { useNavigate } from "react-router-dom";
import { user } from "../lib/helpers/auth";
import { useFormik } from "formik";
import * as Yup from "yup";
type formikValues = {
  amount: number;
  limit: number;
  description: string;
  title: string;
};
const validationSchema = Yup.object().shape({
  amount: Yup.number()
    .required("Please provide an amount")
    .positive("Please use a positive number")
    .min(1, "Please provide an amount"),
  limit: Yup.number()
    .nullable()
    .positive("Please use a positive number")
    .integer("Please use whole numbers"),
  description: Yup.string().nullable().max(100, "Please use 100 chanracters"),
  title: Yup.string()
    .required("Give your link a title")
    .max(20, "Please use 20 characters"),
});

const CreateLink = () => {
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const { isValid, values, handleSubmit, handleChange, errors } = useFormik({
    initialValues: {
      amount: 0,
      limit: 0,
      description: "",
      title: "",
    },
    onSubmit: (values) => handleSubmission(values),
    validationSchema,
    isInitialValid: false,
  });

  const handleSubmission = useCallback(
    async ({ amount, description, limit, title }: formikValues) => {
      if (!user.value?.token || !user.value?.merchant_membership?.id) return;
      if (!title || !description || !amount) {
        setError("Please fill mandatory fields");
        return;
      }
      const res = await fetch(baseUrl + "/links", {
        method: "POST",
        headers: {
          Authorization: "Bearer " + user.value?.token,
          merchantMembership: user.value?.merchant_membership?.id,
        },
        body: JSON.stringify({
          title,
          description,
          amount,
          limit,
        }),
      });
      if (!res.ok) {
        console.error("Network response was not ok");
      }
      const data: Promise<LinkResponse> = res.json();
      data
        .then((link) => navigate("/view-link/" + link.link_id))
        .catch((e) => {
          console.error(e);
        });
    },
    [navigate]
  );

  return (
    <>
      <form onSubmit={handleSubmit}>
        <p className="text-xl">Create a payment link</p>
        <div className="mt-2">
          <span className="text-gray-700 mt-1 text-sm">
            Give your product a title *
          </span>
          <input
            type="text"
            name="title"
            className="mt-1 block w-full rounded-md border-gray-300"
            placeholder=""
            value={values.title}
            onChange={handleChange}
          />
          {errors.title && (
            <span className="text-red-500 text-xs">{errors.title}</span>
          )}
        </div>
        <div className="mt-2">
          <span className="text-gray-700 mt-1 text-sm">
            Describe your product *
          </span>
          <textarea
            className="mt-1 block w-full rounded-md border-gray-300"
            rows={3}
            name="description"
            spellCheck="false"
            value={values.description}
            onChange={handleChange}
          />
          {errors.description && (
            <span className="text-red-500 text-xs">{errors.description}</span>
          )}
        </div>
        <div className="mt-2">
          <span className="text-gray-700 mt-1 text-sm">Amount *</span>
          <input
            type="number"
            name="amount"
            className="mt-1 block w-full rounded-md border-gray-300"
            placeholder=""
            value={values.amount}
            onChange={handleChange}
          />
          {errors.amount && (
            <span className="text-red-500 text-xs">{errors.amount}</span>
          )}
        </div>
        <div className="mt-2">
          <span className="text-gray-700 mt-1 text-sm">
            Payment link limit (optional)
          </span>
          <input
            type="number"
            name="limit"
            className="mt-1 block w-full rounded-md border-gray-300"
            placeholder=""
            value={values.limit}
            onChange={handleChange}
          />
          {errors.limit && (
            <span className="text-red-500 text-xs">{errors.limit}</span>
          )}
        </div>
        {error && <span className="text-red-500">{error}</span>}
        <button
          type="submit"
          className={`block mt-2 w-full rounded-md border-gray-300 ${
            isValid
              ? "bg-[#212121] p-2 text-white"
              : "bg-[#D0CECD] p-2 text-[#212121]"
          }`}
        >
          Create
        </button>
      </form>
    </>
  );
};

export default CreateLink;
