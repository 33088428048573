import React from "react";
import { matchPath, PathMatch } from "react-router-dom";
import Home from "../pages/Home";
import Login from "../pages/Login";
import ListPayIn from "../pages/ListPayIn";
import Checkout from "../pages/Checkout";
import StatusScreen from "../pages/StatusScreen";
import CreateLink from "../pages/CreateLink";
import ViewLink from "../pages/ViewLink";
import EditLink from "../pages/EditLink";
import Receipt from "../pages/Reciept";
import ResetPassword from "../pages/PasswordReset";
import ConfirmEmail from "../pages/PasswordReset/ConfirmEmail";
import CreatePassword from "../pages/PasswordReset/CreatePassword";
import Profile from "../pages/Profile";
import Links from "../pages/Links";
import Payments from "../pages/Payments";
import PayOut from "../pages/Payout";

interface QueryStringParams {
  [key: string]: any;
}

const routes = [
  {
    name: "Home",
    path: "/",
    loggedIn: true,
    redirect: false,
    element: <Home />,
  },
  {
    name: "Login",
    path: "/login",
    loggedIn: false,
    redirect: false,
    element: <Login />,
  },
  {
    name: "Pay Ins",
    path: "/pay-ins",
    loggedIn: true,
    redirect: false,
    element: <ListPayIn />,
  },
  {
    name: "Checkout Page",
    path: "/checkout",
    loggedIn: false,
    redirect: false,
    element: <Checkout />,
  },
  {
    name: "Checkout Page",
    path: "/checkout/:linkId",
    loggedIn: false,
    redirect: false,
    element: <Checkout />,
  },
  {
    name: "Status Screen",
    path: "/status",
    loggedIn: false,
    redirect: false,
    element: <StatusScreen />,
  },
  {
    name: "Create link",
    path: "/create-link",
    loggedIn: true,
    redirect: false,
    element: <CreateLink />,
  },
  {
    name: "Links",
    path: "/links",
    loggedIn: true,
    redirect: false,
    element: <Links />,
  },
  {
    name: "View link",
    path: "/view-link/:linkId",
    loggedIn: true,
    redirect: false,
    element: <ViewLink />,
  },
  {
    name: "Edit link",
    path: "/edit-link",
    loggedIn: true,
    redirect: false,
    element: <EditLink />,
  },
  {
    name: "Receipt",
    path: "/receipt/:orderID",
    loggedIn: false,
    redirect: false,
    element: <Receipt />,
  },
  {
    name: "Payments",
    path: "/payments",
    loggedIn: true,
    redirect: false,
    element: <Payments />,
  },
  {
    name: "Reset password",
    path: "/reset-password",
    loggedIn: false,
    redirect: false,
    element: <ResetPassword />,
  },
  {
    name: "Confirm email",
    path: "/reset-password/confirm",
    loggedIn: false,
    redirect: false,
    element: <ConfirmEmail />,
  },
  {
    name: "Reset password",
    path: "/reset-password/create",
    loggedIn: false,
    redirect: false,
    element: <CreatePassword />,
  },
  {
    name: "Profile",
    path: "/profile",
    loggedIn: true,
    redirect: false,
    element: <Profile />,
  },
  {
    name: "Payout",
    path: "/payout",
    loggedIn: false,
    redirect: false,
    element: <PayOut />,
  },
];

export const fetchAllRoutes = () => {
  return routes;
};

export const getPresentRoute = (
  pathname: string
): { route: any; routeParams: PathMatch<string> | null } => {
  const filteredRoute = routes.filter(
    (route) => matchPath(route.path, pathname) !== null
  )[0];
  // if (!filteredRoute) {
  //   window.location.href = "/";
  // }
  return {
    route: filteredRoute,
    routeParams: matchPath(filteredRoute.path, pathname),
  };
};

export const getRoute = (link_name: string, params: QueryStringParams) => {
  const filteredRoute = routes.filter((route) => route.name === link_name)[0];
  if (filteredRoute) {
    let link = filteredRoute.path;
    let queryStrings: string[] = [];
    Object.keys(params).forEach((key: string) => {
      if (link.includes(`:${key}`)) {
        link = link.replace(`:${key}`, params[key]);
      } else {
        queryStrings.push(`${key}=${params[key]}`);
      }
    });
    return queryStrings.length > 0 ? `${link}?${queryStrings.join("&")}` : link;
  } else {
    return "/";
  }
};

export const RoutePaths = Object.freeze({
  LOGIN: "Login",
  PAY_INS: "Pay Ins",
  CHECKOUT_PAGE: "Checkout Page",
  STATUS_SCREEN: "Status Screen",
});
