import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { LinkResponse, PaymentPollResponse } from "../lib/helpers/interfaces";
import { baseUrl } from "../lib/helpers/constants";
import { ReactComponent as Copy } from "../assets/icons/copy.svg";
import { toast } from "react-toastify";
import { user } from "../lib/helpers/auth";

const ViewLink = () => {
  const { linkId } = useParams();
  const [link, setLink] = useState<LinkResponse>();
  const [payments, setPayments] = useState<PaymentPollResponse[]>();
  const navigate = useNavigate();

  const fetchLink = useCallback(async () => {
    if (!user.value?.token || !user.value?.merchant_membership?.id) {
      console.error("Unauthorised access");
      return;
    }
    try {
      const res = await fetch(baseUrl + "/links/" + linkId, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + user.value?.token,
          merchantMembership: user.value?.merchant_membership?.id,
        },
      });
      const data = await res.json();
      if (data) {
        setLink(data);
      }
    } catch (err) {
      console.error(err);
    }
  }, [linkId]);

  const fetchPayments = useCallback(async () => {
    if (!user.value?.token || !user.value?.merchant_membership?.id) {
      console.error("Unauthorised access");
      return;
    }
    if (!link) return;
    try {
      const res = await fetch(baseUrl + "/orders/link/" + link.id, {
        method: "GET",
        headers: {
          Authorization: "Bearer " + user.value?.token,
          merchantMembership: user.value?.merchant_membership?.id,
        },
      });
      const data: PaymentPollResponse[] = await res.json();
      if (data) {
        setPayments(data);
      }
    } catch (err) {
      console.error(err);
    }
  }, [link]);

  const goToReceipt = useCallback(
    (orderId: string) => navigate("/receipt/" + orderId),
    [navigate]
  );

  const handleCopy = useCallback(() => {
    navigator.clipboard.writeText(
      `https://pgw-web.pages.dev/checkout/${link?.link_id}`
    );
    toast.info("Link copied", {
      position: "bottom-center",
      hideProgressBar: true,
    });
  }, [link?.link_id]);

  useEffect(() => {
    if (!link) return;
    fetchPayments();
  }, [fetchPayments, link]);

  useEffect(() => {
    if (!linkId) return;
    fetchLink();
  }, [fetchLink, linkId]);
  return (
    <>
      <h1 className="text-xl font-medium">{link?.title}</h1>
      <p className="text-[#757575] text-sm">KES {link?.amount}.00</p>
      <p className="text-sm my-2">{link?.description}</p>
      <div className="border p-2 border-[#D0CECD] w-full rounded-lg my-2 flex flex-row justify-between items-center">
        <span>https://pgw-web.pages.dev/checkout/{link?.link_id}</span>
        <Copy onClick={handleCopy} className="h-5 w-5 cursor-pointer" />
      </div>
      <button
        className="w-full mt-4 block rounded-md border-gray-300 bg-blue-600 p-2 text-white"
        onClick={handleCopy}
      >
        Share link
      </button>
      <hr className="my-4" />
      <p className="font-medium">Payments</p>
      {!!payments?.length &&
        payments?.map((payment) => (
          <div
            key={payment.id}
            onClick={() => goToReceipt(payment.id)}
            className="flex flex-row justify-between cursor-pointer items-center py-4 border-b border-b-[EEEEEE]"
          >
            <div>
              <p className="text-lg">{payment.payment_mode}</p>
              <p className="text-[#757575] text-lg">{payment.phone_number}</p>
            </div>
            <p
              className={`text-[#757575] p-1.5 rounded-md ${
                payment.state === "SUCCESS" ? "bg-green-300" : "bg-red-300"
              }`}
            >
              + KES {payment.amount}
            </p>
          </div>
        ))}
    </>
  );
};
export default ViewLink;
