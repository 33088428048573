import { useNavigate } from "react-router-dom";
import { useCallback } from "react";
import { logOut, user } from "../../lib/helpers/auth";

const Profile = () => {
  const navigate = useNavigate();

  const logOff = useCallback(() => {
    logOut();
    navigate("/login");
  }, [navigate]);

  return (
    <>
      <div className="flex flex-row items-center">
        <div className="bg-[#D0CECD] rounded-full h-20 w-20 mr-4"></div>
        <div>
          <p>{user.value?.user.email}</p>
          <p>Admin - ID 3156455E4</p>
        </div>
      </div>
      <hr className="my-5" />
      <h1 className="text-xl font-medium">Account Settings</h1>
      <div
        onClick={() => navigate("/reset-password")}
        className="rounded-lg border border-[#D0D8D8] my-4 p-4"
      >
        <p>Reset Password</p>
      </div>
      <h1 className="text-xl font-medium my-5">More</h1>
      <div
        onClick={logOff}
        className="border-b border-b-[#EEEEEE] cursor-pointer"
      >
        <p className="text-lg my-4">Log out</p>
      </div>
    </>
  );
};

export default Profile;
