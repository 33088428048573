import React, { useState } from "react";
import { baseUrl } from "../lib/helpers/constants";
import { LoginResponse } from "../lib/helpers/interfaces";
import { useNavigate } from "react-router-dom";
import { login } from "../lib/helpers/auth";
import * as Yup from "yup";
import { useFormik } from "formik";
const validationSchema = Yup.object().shape({
  email: Yup.string()
    .email("Please enter a valid email address")
    .required("Email is required"),
  password: Yup.string().required("Please provide a password"),
});

type FormikValues = {
  email: string;
  password: string;
};

const Login: React.FC = (props: any) => {
  const navigate = useNavigate();
  const loginUser = async ({
    email,
    password,
  }: FormikValues): Promise<LoginResponse | undefined> => {
    if (!password || !email) return;
    const res = await fetch(baseUrl + "/users/login", {
      method: "POST",
      body: JSON.stringify({
        email,
        password,
      }),
    });
    if (!res.ok) {
      console.log("Network response was not ok");
      return;
    }
    const data = await res.json();
    login(data);
    navigate("/");
    return data;
  };

  const { values, errors, handleChange, isValid, handleSubmit } = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit: (values) => loginUser(values),
    validationSchema,
    isInitialValid: false,
  });

  return (
    <div>
      <div className="mt-2">
        <span className="text-gray-900 mt-1 text-2xl">Log in</span>
      </div>
      <form onSubmit={handleSubmit}>
        <div className="mt-2">
          <span className="text-gray-700 mt-1 text-sm">Email address</span>
          <input
            type="text"
            name="email"
            className="mt-1 block w-full rounded-md border-gray-300"
            placeholder="example@gmail.com"
            value={values.email}
            onChange={handleChange}
          />
          {errors.email && (
            <span className="text-red-500 text-xs">{errors.email}</span>
          )}
        </div>
        <div className="mt-2">
          <span className="text-gray-700 mt-1 text-sm">Password</span>
          <input
            type="text"
            name="password"
            className="mt-1 block w-full rounded-md border-gray-300"
            placeholder="**********"
            value={values.password}
            onChange={handleChange}
          />
          {errors.password && (
            <span className="text-red-500 text-xs">{errors.password}</span>
          )}
        </div>
        <div className="mt-2">
          <span className="text-gray-700 mt-1 text-sm underline cursor-pointer">
            Forgot password?
          </span>
        </div>

        <button
          className={`block mt-2 w-full rounded-md border-gray-300 p-2 ${
            isValid
              ? "bg-[#212121] text-[#FCFCFC]"
              : "bg-[#D0CECD] text-[#212121]"
          }`}
          type="submit"
        >
          Continue
        </button>
      </form>
    </div>
  );
};

export default Login;
