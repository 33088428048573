import React from "react";
import { XCircle } from "@phosphor-icons/react";
import { useNavigate } from "react-router-dom";

const StatusScreen: React.FC = (props: any) => {
  const navigation = useNavigate();

  return (
    <>
      <div className="text-center">
        <XCircle size={128} weight="regular" style={{ margin: "0 auto" }} />
        <p className="mt-4">Your payment has been unsuccessful</p>
      </div>
      <button
        className="w-full mt-4 block rounded-md border-gray-300 bg-blue-600 p-2 text-white"
        onClick={() => navigation(-1)}
      >
        Go back
      </button>
    </>
  );
};

export default StatusScreen;
