import { useNavigate } from "react-router-dom";
import { ReactComponent as Add } from "../assets/icons/add.svg";
import { useCallback, useEffect, useState } from "react";
import { baseUrl } from "../lib/helpers/constants";
import { LinkResponse } from "../lib/helpers/interfaces";
import { user } from "../lib/helpers/auth";
const Links = () => {
  const navigate = useNavigate();
  const goToLink = useCallback(
    (path: string) => navigate("/view-link/" + path),
    [navigate]
  );

  const [links, setLinks] = useState<LinkResponse[]>();

  const fetchLinks = useCallback(async (): Promise<LinkResponse[]> => {
    if (!user.value?.token || !user.value?.merchant_membership?.id)
      console.error("Unauthorised access");
    const res = await fetch(baseUrl + "/links", {
      method: "GET",
      headers: {
        Authorization: "Bearer " + user.value?.token || "",
        merchantMembership: user.value?.merchant_membership?.id || "",
      },
    });
    const data = await res.json();
    return data;
  }, []);

  useEffect(() => {
    if (!user.value?.token || !user.value?.merchant_membership?.id) return;
    console.log("here");
    fetchLinks().then((data) => setLinks(data));
  }, [fetchLinks]);

  return (
    <>
      <div className="flex flex-row justify-between">
        <h1 className="text-2xl font-medium">Payment links</h1>
        <span
          onClick={() => navigate("/create-link")}
          className="h-6 w-6 cursor-pointer"
        >
          <Add />
        </span>
      </div>
      {!!links?.length &&
        links?.map((link) => (
          <div
            key={link.id}
            onClick={() => goToLink(link.link_id)}
            className="py-4 flex flex-row justify-between cursor-pointer items-center border-b border-b-[EEEEEE]"
          >
            <div>
              <p className="text-lg">{link.title}</p>
              <p className="text-[#757575] text-sm">KES {link.amount}.00</p>
            </div>
            <p>active</p>
          </div>
        ))}
    </>
  );
};
export default Links;
