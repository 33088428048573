import Modal from "./Modal";
import Loader from "../assets/animation/preloader-transparent.gif";

const LoadingModal = () => {
  return (
    <Modal>
      <img height={60} width={60} src={Loader} alt="Loading..." />
    </Modal>
  );
};

export default LoadingModal;
