import { useState } from "react";
import { useNavigate } from "react-router-dom";

const ConfirmEmail = () => {
  const [otp, setOtp] = useState("");
  const navigate = useNavigate();
  const handleNext = () => {
    if (otp.length < 5) return;
    navigate("/reset-password/create");
  };
  return (
    <>
      <h1 className="text-xl font-semibold my-5">Confirm your email address</h1>
      <p className="text-[#494949]">
        Enter 5-digit code sent over SMS to{" "}
        <span className="text-[#212121] font-semibold">abc@example.com</span>{" "}
      </p>
      <input
        type="text"
        className="my-3 block w-full rounded-md border-gray-300"
        placeholder=""
        value={otp}
        onChange={(e) => setOtp(e.target.value)}
      />
      <p className="text-[#494949]">
        Didn’t get a code?{" "}
        <span className="underline text-[#212121] font-semibold cursor-pointer">
          Send again
        </span>
      </p>
      <button
        onClick={handleNext}
        className={`w-full mt-4 block rounded-md p-2 ${
          otp.length < 5
            ? "border-gray-300 bg-[#D0CECD] text-[#212121]"
            : "bg-[#212121] text-white"
        }`}
      >
        Continue
      </button>
    </>
  );
};
export default ConfirmEmail;
