import React, { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { baseUrl } from "../lib/helpers/constants";
import * as yup from "yup";

import {
  AuthPayloadType,
  LinkResponse,
  OrderResponse,
  PaymentPollResponse,
} from "../lib/helpers/interfaces";
import Modal from "../components/Modal";
import { user } from "../lib/helpers/auth";
import { useFormik } from "formik";
import LoadingModal from "../components/LoadingModal";

type formikValues = {
  method: string;
  full_name: string;
  card_number: string;
  card_exp_month: string;
  card_exp_year: string;
  card_cvv: string;
  phone_number: string;
  email: string;
};

const validationSchema = yup.object().shape({
  method: yup.string(),
  full_name: yup
    .string()
    .required("Name is required")
    .max(40, "Max 40 characters"),
  card_number: yup.string().when("method", {
    is: (val: string) => val.includes("CARD"),
    then: () =>
      yup
        .string()
        .required("Card number is required")
        .length(16, "Length should be 16 characters")
        .matches(/^[0-9]+$/, "Must be only digits"),
    otherwise: () => yup.string(),
  }),
  email: yup.string().when("method", {
    is: (val: string) => val.includes("CARD"),
    then: () =>
      yup
        .string()
        .required("Email is required")
        .email("Please use a valid email"),
    otherwise: () => yup.string(),
  }),
  card_exp_month: yup.string().when("method", {
    is: (val: string) => val.includes("CARD"),
    then: () =>
      yup
        .string()
        .required("Card expiry month is required")
        .length(2, "Length should be 2 characters")
        .test(
          "is valid month",
          "Please enter a valid month",
          (value) => parseInt(value) < 13
        )
        .matches(/^[0-9]+$/, "Must be only digits"),
    otherwise: () => yup.string(),
  }),
  card_exp_year: yup.string().when("method", {
    is: (val: string) => val.includes("CARD"),
    then: () =>
      yup
        .string()
        .required("Card expiry year is required")
        .max(2, "Length should be 2 characters")
        .test("is valid month", "Please enter a valid Year", (value) => {
          const year = new Date().getFullYear() - 2000;
          return parseInt(value) >= year;
        })
        .matches(/^[0-9]+$/, "Must be only digits"),
    otherwise: () => yup.string(),
  }),
  card_cvv: yup.string().when("method", {
    is: (val: string) => val.includes("CARD"),
    then: () =>
      yup
        .string()
        .required("Card cvv is required")
        .max(3, "Length should be 3 characters")
        .min(3, "Length should be 3 characters")
        .matches(/^[0-9]+$/, "Must be only digits"),
    otherwise: () => yup.string(),
  }),
  phone_number: yup.string().when("method", {
    is: (val: string) => val.includes("ESA"),
    then: () =>
      yup
        .string()
        .required("Phone number is required")
        .matches(/^254/, "Use the format 254XXXXXXXXX")
        .max(12, "Length should be 12 characters")
        .matches(/^[0-9]+$/, "Must be only digits"),
    otherwise: () => yup.string(),
  }),
});

const Checkout: React.FC = (props: any) => {
  const [paymentMethod, setPaymentMethod] = useState("MPESA");
  const { linkId } = useParams();
  const [link, setLink] = useState<LinkResponse>();
  const [loading, setLoading] = useState(false);
  const [orderID, setOrderID] = useState("");
  const [has3DS, setHas3DS] = useState(false);
  const [is3DSSumbited, setIs3DSSumbited] = useState(false);
  const [authPayload, setAuthPayload] = useState<AuthPayloadType>();
  const navigate = useNavigate();
  const formRef = useRef<HTMLFormElement>(null);
  const btnRef = useRef<HTMLButtonElement>(null);

  const fetchLink = useCallback(async (): Promise<LinkResponse> => {
    const res = await fetch(baseUrl + "/links/" + linkId, {
      method: "GET",
      headers: {
        Authorization: user.value?.token || "",
      },
    });
    const data = await res.json();
    return data;
  }, [linkId]);

  const formik = useFormik({
    initialValues: {
      method: "MPESA",
      full_name: "",
      card_number: "",
      card_exp_month: "",
      card_exp_year: "",
      card_cvv: "",
      email: "",
      phone_number: "",
    },
    onSubmit: (values) => handlePayment(values),
    validationSchema,
    isInitialValid: false,
  });

  useEffect(() => {
    if (!linkId) return;
    fetchLink().then((data) => setLink(data));
  }, [fetchLink, linkId]);

  const pollOrder = useCallback(async () => {
    if (loading && !!orderID) {
      try {
        const res = await fetch(baseUrl + "/orders/" + orderID, {
          method: "GET",
          headers: {
            Authorization: user.value?.token || "",
          },
        });
        const data: PaymentPollResponse = await res.json();
        if (data.state === "SUCCESS") {
          setLoading(false);
          navigate("/receipt/" + data.id);
        } else if (
          data.payment_mode === "CARD" &&
          data.payment_mode_entity.entity.sale_response?.auth_mode === "3ds" &&
          !has3DS
        ) {
          setHas3DS(true);
          setAuthPayload(
            data.payment_mode_entity.entity.sale_response.auth_payload
          );
        } else if (data.state === "PENDING" || data.state === undefined) {
          return;
        } else {
          setLoading(false);
          navigate("/status");
        }
      } catch (e) {
        console.error(e);
      }
    }
  }, [has3DS, loading, navigate, orderID]);

  useEffect(() => {
    if (!loading || !orderID) return;
    const intervalID = setInterval(pollOrder, 1000);
    return () => clearInterval(intervalID);
  }, [loading, orderID, pollOrder]);

  useEffect(() => {
    if (!loading || !orderID || is3DSSumbited || !has3DS) return;
    setTimeout(() => {
      formRef.current?.submit();
      btnRef.current?.click();
      setIs3DSSumbited(true);
    }, 2000); //Timeout allows for the modal to load before attempting to submit the form.
  }, [has3DS, is3DSSumbited, loading, orderID]);

  const handlePayment = async ({
    full_name,
    email,
    card_number,
    card_exp_month,
    card_exp_year,
    card_cvv,
    phone_number,
  }: formikValues) => {
    console.error(formik.isValid);
    if (!formik.isValid || !link) return;
    setLoading(true);
    setOrderID("");
    try {
      const res = await fetch(baseUrl + "/orders", {
        method: "POST",
        headers: {
          Authorization: user.value?.token || "",
        },
        body: JSON.stringify({
          link_id: link.id,
          amount: link.amount,
          payment_mode: paymentMethod,
          phone_number,
          currency: paymentMethod === "MPESA" ? "KES" : "KES",
          full_name,
          email,
          card_number,
          card_exp_month,
          card_exp_year,
          card_cvv,
        }),
      });
      if (!res.ok) {
        console.error("Network response was not ok");
      }
      const data: OrderResponse = await res.json();
      !!data && setOrderID(data.id);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <div>
      {loading && !has3DS && <LoadingModal />}
      <div className="flex flex-row justify-between my-3.5">
        <h1 className="text-sm">Powered by Cashia</h1>
        <h1 className="text-sm">{link?.merchant?.name}</h1>
      </div>
      <hr className="" />
      <p className="text-xl font-bold my-3">Pay KES {link?.amount}.00</p>
      <div className="border border-[#DAD8D8] rounded-lg p-4">
        <div className="flex flex-row justify-between">
          <p className="text-[#757575]">Item:</p>
          <p>{link?.title}</p>
        </div>
        <div className="flex mt-5 flex-row justify-between">
          <p className="text-[#757575]">Pay to</p>
          <p>{link?.merchant?.name}</p>
        </div>
      </div>
      <h1 className="text-lg font-medium my-3">Choose payment method below</h1>
      <ul className="flex flex-wrap justify-left mt-4">
        <li
          onClick={(e) => {
            setPaymentMethod("MPESA");
            formik.setFieldValue("method", "MPESA");
          }}
          className={
            paymentMethod === "MPESA"
              ? "border-b-2 border-gray-800 mr-4 cursor-pointer"
              : "mr-4 cursor-pointer"
          }
        >
          M-PESA
        </li>
        <li
          onClick={(e) => {
            setPaymentMethod("CARD");
            formik.setFieldValue("method", "CARD");
          }}
          className={
            paymentMethod === "CARD"
              ? "border-b-2 border-gray-800 mr-4 cursor-pointer"
              : "mr-4 cursor-pointer"
          }
        >
          Card
        </li>
      </ul>
      <form onSubmit={formik.handleSubmit}>
        {paymentMethod === "CARD" && (
          <>
            <div className="mt-2">
              <span className="text-gray-700 mt-1 text-sm">Name on Card</span>
              <input
                type="text"
                name="full_name"
                className="mt-1 block w-full rounded-md border-gray-300"
                placeholder=""
                value={formik.values.full_name}
                onChange={formik.handleChange}
              />
              {formik.errors.full_name && (
                <span className="text-red-500 text-xs">
                  {formik.errors.full_name}
                </span>
              )}
            </div>
            <div className="mt-2">
              <span className="text-gray-700 mt-1 text-sm">Email</span>
              <input
                type="email"
                name="email"
                className="mt-1 block w-full rounded-md border-gray-300"
                placeholder=""
                value={formik.values.email}
                onChange={formik.handleChange}
              />
              {formik.errors.email && (
                <span className="text-red-500 text-xs">
                  {formik.errors.email}
                </span>
              )}
            </div>
            <div className="mt-2">
              <span className="text-gray-700 mt-1 text-sm">Card Number</span>
              <input
                type="text"
                name="card_number"
                className="mt-1 block w-full rounded-md border-gray-300"
                placeholder=""
                value={formik.values.card_number}
                onChange={formik.handleChange}
              />
              {formik.errors.card_number && (
                <span className="text-red-500 text-xs">
                  {formik.errors.card_number}
                </span>
              )}
            </div>
            <div className="mt-2">
              <span className="text-gray-700 mt-1 text-sm">Card Expiry</span>

              <div className="flex flex-row">
                <div className="flex flex-col">
                  <input
                    type="text"
                    name="card_exp_month"
                    className="mt-1 block w-full rounded-md border-gray-300"
                    value={formik.values.card_exp_month}
                    placeholder="MM"
                    onChange={formik.handleChange}
                  />
                  {formik.errors.card_exp_month && (
                    <span className="text-red-500 text-xs mt-1">
                      {formik.errors.card_exp_month}
                    </span>
                  )}
                </div>
                <div className="flex flex-col">
                  <input
                    type="text"
                    name="card_exp_year"
                    className="mt-1 block w-full rounded-md border-gray-300"
                    value={formik.values.card_exp_year}
                    placeholder="YY"
                    onChange={formik.handleChange}
                  />
                  {formik.errors.card_exp_year && (
                    <span className="text-red-500 text-xs mt-1">
                      {formik.errors.card_exp_year}
                    </span>
                  )}
                </div>
              </div>
            </div>
            <div className="mt-2">
              <span className="text-gray-700 mt-1 text-sm">CVV Number</span>
              <input
                type="password"
                name="card_cvv"
                className="mt-1 block w-full rounded-md border-gray-300"
                placeholder=""
                value={formik.values.card_cvv}
                onChange={formik.handleChange}
              />
              {formik.errors.card_cvv && (
                <span className="text-red-500 text-xs">
                  {formik.errors.card_cvv}
                </span>
              )}
            </div>
            {has3DS && (
              <Modal>
                <>
                  <iframe
                    title="3ds-auth"
                    name="3ds"
                    height="400"
                    width="400"
                  ></iframe>
                  <form
                    id="3ds"
                    target="3ds"
                    method="post"
                    ref={formRef}
                    action={authPayload?.action}
                  >
                    {authPayload?.params &&
                      Object.keys(authPayload?.params).map((key) => {
                        return (
                          <input
                            type="hidden"
                            name={key}
                            value={authPayload?.params[key]}
                          />
                        );
                      })}
                    {/* <input
                      type="hidden"
                      name="PaReq"
                      value={authPayload?.params?.PaReq}
                    />
                    <input
                      type="hidden"
                      name="MD"
                      value={authPayload?.params?.MD}
                    />
                    <input
                      type="hidden"
                      name="TermUrl"
                      value={authPayload?.params?.TermUrl}
                    /> */}
                    <button hidden ref={btnRef} type="submit">
                      Button 3DS
                    </button>
                  </form>
                </>
              </Modal>
            )}
          </>
        )}
        {paymentMethod === "MPESA" && (
          <>
            <div className="mt-2">
              <span className="text-gray-700 mt-1 text-sm">Full name</span>
              <input
                type="text"
                name="full_name"
                className="mt-1 block w-full rounded-md border-gray-300"
                placeholder=""
                value={formik.values.full_name}
                onChange={formik.handleChange}
              />
              {formik.errors.full_name && (
                <span className="text-red-500 text-xs">
                  {formik.errors.full_name}
                </span>
              )}
            </div>
            <div className="mt-2">
              <span className="text-gray-700 mt-1 text-sm">
                Enter phone number
              </span>
              <input
                type="text"
                name="phone_number"
                className="mt-1 block w-full rounded-md border-gray-300"
                placeholder="254722123123"
                value={formik.values.phone_number}
                onChange={formik.handleChange}
              />
              {formik.errors.phone_number && (
                <span className="text-red-500 text-xs">
                  {formik.errors.phone_number}
                </span>
              )}
            </div>
            <p className="text-red-500 text-xs mt-4">
              By typing your number and selecting the pay button, you will
              receive a prompt to enter your <u>PIN</u> and pay the total amount
            </p>
          </>
        )}
        <button
          className={`w-full mt-4 block rounded-md border-gray-300  ${
            formik.isValid ? "bg-[#212121] text-[#FCFCFC]" : "bg-[#D0CECD]"
          } p-2 text-[#212121]`}
          type="submit"
        >
          Pay Now
        </button>
      </form>
    </div>
  );
};

export default Checkout;
