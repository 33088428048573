import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { baseUrl } from "../lib/helpers/constants";
import { OrderResponse } from "../lib/helpers/interfaces";
import { user } from "../lib/helpers/auth";

const Payments = () => {
  const navigate = useNavigate();
  const goToReceipt = useCallback(
    (orderId: string) => navigate("/receipt/" + orderId),
    [navigate]
  );
  const [payments, setPayments] = useState<OrderResponse[]>();

  const fetchPayments = useCallback(async (): Promise<OrderResponse[]> => {
    if (!user.value?.token || !user.value?.merchant_membership?.id)
      throw new Error("Unauthorised access");
    const res = await fetch(baseUrl + "/orders", {
      method: "GET",
      headers: {
        Authorization: "Bearer " + user.value?.token,
        merchantMembership: user.value?.merchant_membership?.id,
      },
    });
    const data = await res.json();
    return data;
  }, []);

  useEffect(() => {
    if (!user.value?.token || !user.value?.merchant_membership?.id) return;
    fetchPayments().then((data) => setPayments(data));
  }, [fetchPayments]);

  return (
    <>
      <h1 className="text-2xl font-medium">Payments</h1>

      {!!payments?.length &&
        payments?.map((payment) => (
          <div
            key={payment.id}
            onClick={() => goToReceipt(payment.id)}
            className="flex flex-row justify-between cursor-pointer items-center py-4 border-b border-b-[EEEEEE]"
          >
            <div>
              <p className="text-lg">{payment.payment_mode}</p>
              <p className="text-[#757575] text-lg">{payment.phone_number}</p>
            </div>
            <p className="text-[#757575]">+ KES {payment.amount}</p>
          </div>
        ))}
    </>
  );
};

export default Payments;
