import React, { useCallback, useState } from "react";
import { ReactComponent as Home } from "../assets/icons/home.svg";
import { ReactComponent as HomeFilled } from "../assets/icons/home_filled.svg";
import { ReactComponent as Link } from "../assets/icons/links.svg";
import { ReactComponent as LinkFilled } from "../assets/icons/links_filled.svg";
import { ReactComponent as Payment } from "../assets/icons/payments.svg";
import { ReactComponent as PaymentFilled } from "../assets/icons/payments_filled.svg";
import { ReactComponent as Profile } from "../assets/icons/profile.svg";
import { ReactComponent as ProfileFilled } from "../assets/icons/profile_filled.svg";
import { useNavigate } from "react-router-dom";

const Footer: React.FC = () => {
  const [selected, setSelected] = useState("home");
  const navigate = useNavigate();

  const isSelected = useCallback(
    (link: string) => link === selected,
    [selected]
  );

  const handleLink = useCallback(
    (link: string) => {
      setSelected(link);
      link === "home" ? navigate(`/`) : navigate(`/${link}`);
    },
    [navigate]
  );

  return (
    <footer className="mt-10 pb-3 flex items-center justify-between">
      {!isSelected("home") ? (
        <div
          onClick={() => handleLink("home")}
          className="flex flex-col items-center cursor-pointer"
        >
          <Home />
          <p className="text-sm text-[#757575]">Home</p>
        </div>
      ) : (
        <div
          onClick={() => handleLink("home")}
          className="flex flex-col items-center cursor-pointer"
        >
          <HomeFilled />
          <p className="text-sm font-medium text-[#212121]">Home</p>
        </div>
      )}
      {isSelected("links") ? (
        <div
          onClick={() => handleLink("links")}
          className="flex flex-col items-center cursor-pointer"
        >
          <LinkFilled />
          <p className="text-sm text-[#212121]">Link</p>
        </div>
      ) : (
        <div
          onClick={() => handleLink("links")}
          className="flex flex-col items-center cursor-pointer"
        >
          <Link />
          <p className="text-sm text-[#757575]">Link</p>
        </div>
      )}
      {isSelected("payments") ? (
        <div
          onClick={() => handleLink("payments")}
          className="flex flex-col items-center cursor-pointer"
        >
          <PaymentFilled />
          <p className="text-sm text-[#212121]">Payments</p>
        </div>
      ) : (
        <div
          onClick={() => handleLink("payments")}
          className="flex flex-col items-center cursor-pointer"
        >
          <Payment />
          <p className="text-sm text-[#757575]">Payments</p>
        </div>
      )}
      {isSelected("profile") ? (
        <div
          onClick={() => handleLink("profile")}
          className="flex flex-col items-center cursor-pointer"
        >
          <ProfileFilled />
          <p className="text-sm text-[#212121]">Profile</p>
        </div>
      ) : (
        <div
          onClick={() => handleLink("profile")}
          className="flex flex-col items-center cursor-pointer"
        >
          <Profile />
          <p className="text-sm text-[#757575]">Profile</p>
        </div>
      )}
    </footer>
  );
};

export default Footer;
