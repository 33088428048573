import { useState } from "react";
import { useNavigate } from "react-router-dom";

const ResetPassword = () => {
  const [email, setEmail] = useState("");
  const navigate = useNavigate();
  const handleNext = () => {
    if (email.length < 5) return;
    navigate("/reset-password/confirm");
  };
  return (
    <>
      <h1>Let's get started</h1>
      <p>
        Please enter the email address you used to create your account below.
      </p>
      <input
        type="text"
        className="mt-2 block w-full rounded-md border-gray-300"
        placeholder=""
        value={email}
        onChange={(e) => setEmail(e.target.value)}
      />
      <button
        onClick={handleNext}
        className={`w-full mt-4 block rounded-md p-2 ${
          email.length < 5
            ? "border-gray-300 bg-[#D0CECD] text-[#212121]"
            : "bg-[#212121] text-white"
        }`}
      >
        Continue
      </button>
    </>
  );
};

export default ResetPassword;
