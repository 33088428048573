import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { BalanceResponse, LinkResponse } from "../lib/helpers/interfaces";
import { baseUrl } from "../lib/helpers/constants";
import { user } from "../lib/helpers/auth";

const Home: React.FC = () => {
  const navigate = useNavigate();
  const goToCreateLink = () => navigate("/create-link");
  const [links, setLinks] = useState<LinkResponse[]>();
  const [bal, setBal] = useState(0);

  const goToLink = useCallback(
    (path: string) => navigate("/view-link/" + path),
    [navigate]
  );

  const initiatePayout = () => navigate("/payout");

  const fetchLinks = useCallback(async () => {
    try {
      const res = await fetch(baseUrl + "/links", {
        method: "GET",
        headers: {
          Authorization: "Bearer " + user.value?.token || "",
          merchantMembership: user.value?.merchant_membership?.id || "",
        },
      });
      const data: LinkResponse[] = await res.json();
      if (data) setLinks(data);
    } catch (err) {
      console.error(err);
    }
  }, []);

  const fetchBalance = useCallback(async () => {
    if (!user.value?.token || !user.value?.merchant_membership?.id) return;
    try {
      const res = await fetch(baseUrl + "/merchants/balances", {
        method: "GET",
        headers: {
          Authorization: "Bearer " + user.value?.token,
          merchantMembership: user.value?.merchant_membership?.id,
        },
      });
      const data: BalanceResponse = await res.json();
      if (data) setBal(data.balance);
    } catch (err) {
      console.error(err);
    }
  }, []);

  useEffect(() => {
    if (!user.value?.token || !user.value?.merchant_membership?.id) return;
    fetchLinks();
    fetchBalance();
  }, [fetchBalance, fetchLinks]);

  return (
    <div className="max-w-md mx-auto">
      <h1 className="text-3xl font-bold mb-4">Hi {user.value?.user.email}</h1>
      <div className="w-full rounded-lg border border-[#DAD8D8] p-4">
        <p className="text-[#757575]">Balance</p>
        <p className="text-xl font-semibold">KES {bal}.00</p>
      </div>
      <div
        onClick={initiatePayout}
        className="my-2 border cursor-pointer border-[#DAD8D8] px-4 py-3 rounded-lg"
      >
        <p className="text-[#757575]">Initiate Payout</p>
      </div>
      <div
        onClick={goToCreateLink}
        className="my-2 border cursor-pointer border-[#DAD8D8] px-4 py-3 rounded-lg"
      >
        <p className="text-[#757575]">Create a payment link</p>
      </div>
      {!!links?.length && (
        <>
          <div className="flex flex-row justify-between my-4">
            <p className="text-sm">PAYMENT LINKS</p>
            <p className="text-sm text-[#757575]">View all</p>
          </div>
          {links?.map((link) => (
            <div
              key={link.id}
              onClick={() => goToLink(link.link_id)}
              className="flex flex-row justify-between items-center my-4 cursor-pointer"
            >
              <div>
                <p>{link.title}</p>
                <p className="text-sm text-[#757575]">KES {link.amount}.00</p>
              </div>
              <p className="text-sm">active</p>
            </div>
          ))}
        </>
      )}
    </div>
  );
};

export default Home;
