import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { baseUrl } from "../lib/helpers/constants";

import { PaymentPollResponse } from "../lib/helpers/interfaces";
import { isLoggedIn, user } from "../lib/helpers/auth";
import toTitleCase from "../lib/helpers/toTitleCase";

const Receipt = () => {
  const navigate = useNavigate();
  const { orderID } = useParams();
  const [order, setOrder] = useState<PaymentPollResponse>();

  const fetchOrder = useCallback(async () => {
    if (!orderID) {
      navigate(-1);
      return;
    }
    try {
      const res = await fetch(baseUrl + "/orders/" + orderID, {
        method: "GET",
        headers: {
          Authorization: user.value?.token || "",
        },
      });
      const data: PaymentPollResponse = await res.json();
      if (data) setOrder(data);
    } catch (err) {
      console.log(err);
    }
  }, [navigate, orderID]);

  const fetchMerchant = useCallback(async () => {
    if (!orderID) {
      navigate(-1);
      return;
    }
    try {
      const res = await fetch(baseUrl + "/merchant/" + orderID, {
        method: "GET",
        headers: {
          Authorization: user.value?.token || "",
        },
      });
      const data: PaymentPollResponse = await res.json();
      if (data) setOrder(data);
    } catch (err) {
      console.log(err);
    }
  }, [navigate, orderID]);

  useEffect(() => {
    if (!orderID) return;
    fetchOrder();
  }, [fetchOrder, orderID]);
  const customerIdentifier = useMemo(
    () => order?.full_name,
    [order?.full_name]
  );

  return (
    <>
      <div className="bg-gradient-to-b from-[#2121212e] mb-6 from-1% to-100% to-[#2121217d] items-center flex justify-center h-20 relative">
        <p className="text-sm">21 Jul, 2024, 18:37</p>
        <div className="absolute bottom-[-20px] left-4 h-10 w-10 rounded-lg bg-[#C4C4C4]"></div>
      </div>
      <p className="text-xl font-semibold">
        {`${!isLoggedIn.value ? "You Paid" : "You received"} ${
          order?.currency
        } ${order?.amount}.00`}
      </p>
      <p className="text-xs text-[#757575] my-1">{`${
        isLoggedIn.value
          ? `From ${customerIdentifier}`
          : `To ${order?.link?.merchant?.name}`
      }`}</p>
      <p>Payment details</p>
      <div className="border border-[#DAD8D8] rounded-lg p-4 my-3">
        <div className="flex flex-row justify-between">
          <p className="text-[#757575]">Status:</p>
          <p>{order?.state === "SUCCESS" ? "Successful" : "Unsuccessful"}</p>
        </div>
        <div className="mt-6 flex flex-row justify-between">
          <p className="text-[#757575]">Category</p>
          <p>Payments</p>
        </div>
      </div>
      <p>Transaction details</p>
      <div className="border border-[#DAD8D8] rounded-lg p-4 my-3">
        <div className="flex flex-row justify-between">
          <p className="text-[#757575]">Method:</p>
          <p>
            {order?.payment_mode === "CARD"
              ? toTitleCase(
                  order?.payment_mode_entity?.entity?.card_details?.network
                )
              : toTitleCase(order?.payment_mode)}
          </p>
        </div>
        <div className="mt-6 flex flex-row justify-between">
          <p className="text-[#757575]">Transaction ID:</p>
          <p>EKG2SJFN</p>
        </div>
      </div>
      {!isLoggedIn.value && (
        <>
          <p>From</p>
          <div className="border border-[#DAD8D8] items-center my-2 rounded-lg p-3 flex flex-row">
            <div className="rounded-md bg-[#C4C4C4] mr-4 h-8 w-8" />
            {order?.payment_mode === "CARD" ? (
              <div className="flex flex-col">
                <p>
                  {toTitleCase(
                    order?.payment_mode_entity?.entity?.card_details?.holder
                  )}
                </p>
                <p className="text-sm text-[#757575]">
                  {`${toTitleCase(
                    order?.payment_mode_entity?.entity?.card_details?.network
                  )} ${order?.payment_mode_entity?.entity?.card_details?.last}`}
                </p>
              </div>
            ) : (
              <p className="text-sm text-[#757575]">{`+ ${order?.phone_number}`}</p>
            )}
          </div>
        </>
      )}
    </>
  );
};

export default Receipt;
