import { setegid } from "process";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

const CreatePassword = () => {
  const [pass, setPass] = useState("");
  const [passTwo, setPassTwo] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();
  const handleNext = () => {
    if (pass.length < 8 || passTwo.length < 8) {
      setError("Password too short");
      return;
    }
    if (pass.length !== passTwo.length) {
      setError("Passwords don't match");
      return;
    }
    navigate("/profile");
  };
  return (
    <>
      <h1 className="text-xl font-semibold my-5">Create a new password</h1>
      <p>Enter your new password</p>
      <input
        type="password"
        className="my-3 block w-full rounded-md border-gray-300"
        placeholder=""
        value={pass}
        onChange={(e) => setPass(e.target.value)}
      />
      <p>Confirm your new password</p>
      <input
        type="password"
        className="my-3 block w-full rounded-md border-gray-300"
        placeholder=""
        value={passTwo}
        onChange={(e) => setPassTwo(e.target.value)}
      />
      {error && <span className="text-red-500 text-lg">{error}</span>}

      <button
        onClick={handleNext}
        className={`w-full mt-4 block rounded-md p-2 ${
          passTwo.length < 5
            ? "border-gray-300 bg-[#D0CECD] text-[#212121]"
            : "bg-[#212121] text-white"
        }`}
      >
        Continue
      </button>
    </>
  );
};
export default CreatePassword;
