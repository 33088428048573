import React, { useState } from "react";

const EditLink = () => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [amount, setAmount] = useState(0);
  const [limit, setLimit] = useState(0);
  return (
    <>
      <p className="text-xl">Edit your payment link</p>
      <div className="mt-2">
        <span className="text-gray-700 mt-1 text-sm">
          Give your product a title
        </span>
        <input
          type="text"
          className="mt-1 block w-full rounded-md border-gray-300"
          placeholder=""
          value={title}
          onChange={(e) => setTitle(e.target.value)}
        />
      </div>
      <div className="mt-2">
        <span className="text-gray-700 mt-1 text-sm">
          Describe your product
        </span>
        <textarea
          className="mt-1 block w-full rounded-md border-gray-300"
          rows={3}
          spellCheck="false"
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        ></textarea>
      </div>
      <div className="mt-2">
        <span className="text-gray-700 mt-1 text-sm">Amount</span>
        <input
          type="number"
          className="mt-1 block w-full rounded-md border-gray-300"
          placeholder=""
          value={amount}
          onChange={(e) => setAmount(parseInt(e.target.value))}
        />
      </div>
      <div className="mt-2">
        <span className="text-gray-700 mt-1 text-sm">
          Payment link limit (optional)
        </span>
        <input
          type="number"
          className="mt-1 block w-full rounded-md border-gray-300"
          placeholder=""
          value={limit}
          onChange={(e) => setLimit(parseInt(e.target.value))}
        />
      </div>
      <button className="block mt-2 w-full rounded-md border-gray-300 bg-[#D0CECD] p-2 text-white">
        Save
      </button>
    </>
  );
};
export default EditLink;
