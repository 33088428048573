import React, { useEffect } from "react";
// import logo from './logo.svg';
import "./assets/css/App.css";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import {
  RoutePaths,
  fetchAllRoutes,
  getPresentRoute,
  getRoute,
} from "./lib/RouterHelper";
import Footer from "./components/Footer";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import { isLoggedIn } from "./lib/helpers/auth";

const App: React.FC = () => {
  const location = useLocation();
  // const queryParams = new URLSearchParams(window.location.search);
  // const { route, routeParams } = getPresentRoute(location.pathname);

  useEffect(() => {
    const { route } = getPresentRoute(location.pathname);
    if (!route) {
      window.location.href = "/";
    } else {
      if (route.loggedIn && !isLoggedIn.value) {
        window.location.href = getRoute(RoutePaths.LOGIN, {
          redirect: route.path,
        });
      }
    }
  }, [location]);

  return (
    <div className="mx-auto pt-4 max-w-sm h-screen flex flex-col ">
      <main className="flex-1 overflow-auto">
        <Routes>
          {fetchAllRoutes().map((r) => {
            if (r.redirect === undefined || !r.redirect) {
              return (
                <Route
                  key={`${r.name}-route`}
                  path={r.path}
                  element={r.element}
                />
              );
            } else if (r.redirect) {
              return <Route path={r.path} element={<Navigate to="/login" />} />;
            } else {
              return <Route path={r.path} element={<Navigate to="/login" />} />;
            }
          })}
        </Routes>
      </main>
      <ToastContainer />
      <Footer />
    </div>
  );
};

export default App;
