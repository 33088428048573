import React from "react";

const ListPayIn: React.FC = (props: any) => {
    return (
        <>
            <h2 className='text-xl mb-4'>Pay Ins</h2>
            <div>
                <div className="rounded-md border-2 border-gray-300 text-sm p-2 mb-4">
                    <p className='mb-2'>This is a payment for purchasing X product</p>
                    <div className="flex justify-between">
                        <div className="justify-start">
                            <p className='m-0'><b>Name:</b> John Doe</p>
                            <p className='m-0'><b>Email:</b> johndoe@gmail.com</p>
                            <p className='m-0'><b>Mobile:</b> +919912244886</p>
                        </div>
                        <div className='justify-end text-right text-xl'>USD 200</div>
                    </div>
                    <div className="flex justify-between border-t-2 border-gray-300 p-2 mt-2">
                        <p>Date: 12th Jan, 2024</p>
                        <p className='text-yellow-600'>Status: Pending</p>
                    </div>
                </div>
                <div className="rounded-md border-2 border-gray-300 text-sm p-2 mb-4">
                    <p className='mb-2'>This is a payment for purchasing X product</p>
                    <div className="flex justify-between">
                        <div className="justify-start">
                            <p className='m-0'><b>Name:</b> John Doe</p>
                            <p className='m-0'><b>Email:</b> johndoe@gmail.com</p>
                            <p className='m-0'><b>Mobile:</b> +919912244886</p>
                        </div>
                        <div className='justify-end text-right text-xl'>USD 200</div>
                    </div>
                    <div className="flex justify-between border-t-2 border-gray-300 p-2 mt-2">
                        <p>Date: 12th Jan, 2024</p>
                        <p className='text-green-500'>Status: Success</p>
                    </div>
                </div>
                <div className="rounded-md border-2 border-gray-300 text-sm p-2 mb-4">
                    <p className='mb-2'>This is a payment for purchasing X product</p>
                    <div className="flex justify-between">
                        <div className="justify-start">
                            <p className='m-0'><b>Name:</b> John Doe</p>
                            <p className='m-0'><b>Email:</b> johndoe@gmail.com</p>
                            <p className='m-0'><b>Mobile:</b> +919912244886</p>
                        </div>
                        <div className='justify-end text-right text-xl'>USD 200</div>
                    </div>
                    <div className="flex justify-between border-t-2 border-gray-300 p-2 mt-2">
                        <p>Date: 12th Jan, 2024</p>
                        <p className='text-red-700'>Status: Failed</p>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ListPayIn;